import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta:{
      title:'首页'
    },
    component: () => import('@/views/Index/index')
  },
  {
    path: '/companyAbstract',
    name: 'companyAbstract',
    meta:{
      title:'企业简介'
    },
    component: () => import('@/views/CompanyAbstract/CompanyAbstract')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    meta:{
      title:'联系我们'
    },
    component: () => import('@/views/ContactUs/ContactUs')
  },
  {
    path: '/serviceAgreement',
    name: 'serviceAgreement',
    meta:{
      title:'服务协议'
    },
    component: () => import('@/views/ServiceAgreement/ServiceAgreement')
  },
  {
    path: '*',  //*号表示匹配任意内容
    redirect: '/'
  }
]

const router = new VueRouter({
  // 刷新页面置顶
  scrollBehavior(to, from,savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0); //切换路由之后滚动条始终在最顶部
});
// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
export default router
